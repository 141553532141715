import {isoDocument} from './document';
import {isoWindow} from './window';

const OriginalHTMLElement = isoWindow.HTMLElement;

export const createCustomElement = (tag: string) => {
  const ModifiedHTMLElement = isoWindow.HTMLElement;
  // Restore the original HTMLElement before creating the element
  isoWindow.HTMLElement = OriginalHTMLElement;
  const element = isoDocument.createElement(tag);

  // Restore the modified HTMLElement
  isoWindow.HTMLElement = ModifiedHTMLElement;

  return element;
};
