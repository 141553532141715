import {isoNavigator} from './navigator';
import {isoWindow} from './window';

export function getQueryParam(name: string): string | null {
  const urlParams = new URLSearchParams(isoWindow.location.search);
  return urlParams.get(name);
}

export function isMobileBrowser(): boolean {
  return (
    Boolean(isoNavigator.userAgent) &&
    /(android|iphone|ipad|mobile|phone)/i.test(isoNavigator.userAgent)
  );
}

export function removeTrailingSlash(value: string): string {
  if (value === '/') return value;
  return value.endsWith('/') ? value.slice(0, -1) : value;
}
