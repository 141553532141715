import type {RefCallback, RefObject} from 'preact';
import {forwardRef} from 'preact/compat';
import {
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'preact/hooks';

import {useBugsnag} from '~/foundation/Bugsnag/hooks';
import {useMonorail} from '~/foundation/Monorail/hooks';
import {useOpenTelemetry} from '~/foundation/OpenTelemetry/hooks';
import type {AuthorizeEventHandlers} from '~/hooks/useAuthorizeEventListener';
import {useAuthorizeEventListener} from '~/hooks/useAuthorizeEventListener';
import {useDispatchEvent} from '~/hooks/useDispatchEvent';
import {useLoadTimeout} from '~/hooks/useLoadTimeout';
import {usePrevious} from '~/hooks/usePrevious';
import type {ShopifyPayModalState} from '~/types/analytics';
import type {SdkErrorCode} from '~/types/authorize';
import type {PayEvents} from '~/types/event';
import type {IframeElement} from '~/types/iframe';
import type {PortalProviderVariant} from '~/types/portalProvider';
import {isoDocument} from '~/utils/document';
import {postMessage} from '~/utils/postMessage';
import {isoWindow} from '~/utils/window';

import {Modal} from '../Modal/Modal';

export interface AuthorizeIframeProps extends AuthorizeEventHandlers {
  activator?: RefObject<HTMLElement>;
  allowAttribute?: string;
  anchorTo?: string;
  autoOpen?: boolean;
  disableDefaultIframeResizing?: boolean;
  keepModalOpen?: boolean;
  modalHeaderVisible?: boolean;
  proxy: boolean;
  src: string;
  storefrontOrigin?: string;
  variant: PortalProviderVariant;
}

const SILENT_SDK_ERROR_CODES: SdkErrorCode[] = ['captcha_challenge'];

export const AuthorizeIframe = forwardRef<IframeElement, AuthorizeIframeProps>(
  (
    {
      activator,
      allowAttribute,
      anchorTo,
      autoOpen,
      disableDefaultIframeResizing = false,
      keepModalOpen = false,
      modalHeaderVisible = true,
      onComplete,
      onError,
      onLoaded,
      onModalVisibleChange,
      onResizeIframe,
      proxy,
      src,
      storefrontOrigin,
      variant,
    },
    ref,
  ) => {
    const {analyticsData, produceMonorailEvent, trackPageImpression} =
      useMonorail();
    const {leaveBreadcrumb, notify} = useBugsnag();
    const {recordCounter} = useOpenTelemetry();
    const {clearLoadTimeout, initLoadTimeout} = useLoadTimeout();
    const iframeRef = useRef<HTMLIFrameElement | null>(null);
    const [loaded, setLoaded] = useState(false);
    const [modalVisible, setModalVisible] = useState(false);
    const [sessionDetected, setSessionDetected] = useState(false);
    const prevModalVisible = usePrevious(modalVisible);
    const dispatchEvent = useDispatchEvent();

    const callbackRef: RefCallback<HTMLIFrameElement> = (ref) => {
      /**
       * When the user restarts, the render logic changes for our iframe and ref is set to null.
       * We want to prevent from overriding the ref and setting it to null here because it breaks
       * the postMessage logic.
       */
      if (!ref) {
        return;
      }

      iframeRef.current = ref;

      /**
       * Init iframe src if not set already. We need this because iframeRef.current is undefined on
       * initial render, therefore we're not guaranteeed to successfully set the iframe src via
       * iframeRef.
       */
      if (!ref.getAttribute('src')) {
        ref.setAttribute('src', src);
      }
    };

    const trackModalStateChange = useCallback(
      (currentState: ShopifyPayModalState, zoom: string) => {
        const {
          analyticsTraceId,
          checkoutToken,
          flow,
          flowVersion = 'unspecified',
        } = analyticsData;

        if (!analyticsTraceId || !flow) {
          return;
        }

        produceMonorailEvent({
          event: {
            schemaId: 'shop_identity_modal_state_change/1.2',
            payload: {
              analyticsTraceId,
              currentState,
              checkoutToken,
              flow,
              flowVersion,
              zoom,
            },
          },
        });
      },
      [analyticsData, produceMonorailEvent],
    );

    const handleShowModal = useCallback(() => {
      setModalVisible(true);
      trackModalStateChange('shown', `${isoWindow.visualViewport?.scale}`);
    }, [trackModalStateChange]);

    const handleDismissModal = useCallback(() => {
      setModalVisible(false);

      // Focuses the activator after the modal closes.
      if (activator?.current && isRef(activator)) {
        activator.current.focus();
      }
      trackModalStateChange('hidden', `${isoWindow.visualViewport?.scale}`);
    }, [activator, trackModalStateChange]);

    const postMessageCallback = useCallback(
      (event: PayEvents) =>
        postMessage({
          contentWindow: iframeRef?.current?.contentWindow,
          event,
        }),
      [],
    );

    useEffect(() => {
      function onClick() {
        handleShowModal();
      }

      const internalActivatorRef = activator;

      if (internalActivatorRef?.current && isRef(internalActivatorRef)) {
        internalActivatorRef.current.addEventListener('click', onClick);

        return () => {
          internalActivatorRef.current?.removeEventListener('click', onClick);
        };
      }
    }, [activator, handleShowModal]);

    const {destroy, waitForMessage} = useAuthorizeEventListener({
      includeCore: proxy,
      onClose: handleDismissModal,
      onComplete: (completedEvent) => {
        if (!keepModalOpen) {
          handleDismissModal();
        }
        onComplete?.(completedEvent);
      },
      onError: (event) => {
        const {message, code} = event;
        if (SILENT_SDK_ERROR_CODES.includes(code)) {
          recordCounter('shop_js_handle_silent_error', {
            attributes: {
              errorCode: code,
            },
          });
          leaveBreadcrumb('silent error', {code}, 'state');
        } else {
          notify(new Error(`Authorize Error: ${message} (${code}).`));
        }

        clearLoadTimeout();
        onError?.(event);
      },
      onLoaded: (event) => {
        trackModalStateChange('loaded', `${isoWindow.visualViewport?.scale}`);
        leaveBreadcrumb('iframe loaded', {}, 'state');
        setSessionDetected(event.userFound);
        setLoaded(true);
        onLoaded?.(event);
        clearLoadTimeout();

        /**
         * web-component compatibility since attributes on web components are not boolean based.
         * Check if the prop is defined as an attribute and that it is not set to false explicitly.
         */
        if (event.userFound && autoOpen !== undefined && autoOpen !== false) {
          setModalVisible(true);
        }
      },
      onResizeIframe: (event) => {
        if (!disableDefaultIframeResizing) {
          if (iframeRef.current) {
            iframeRef.current.style.height = `${event.height}px`;
          }
        }

        onResizeIframe?.(event);
      },
      onShopUserMatched: ({userCookieExists}) => {
        dispatchEvent('shopusermatched');
        setSessionDetected(userCookieExists);
        leaveBreadcrumb('shop user matched', {}, 'state');
      },
      onShopUserNotMatched: ({apiError}) => {
        dispatchEvent('shopusernotmatched', apiError && {apiError});
        setSessionDetected(false);
        leaveBreadcrumb('shop user not matched', {}, 'state');
      },
      source: iframeRef,
      storefrontOrigin,
    });

    useEffect(() => {
      return () => {
        if (iframeRef.current) {
          destroy();
        }
      };
    }, [destroy]);

    useEffect(() => {
      // Do not run the effect if the modalVisible state has not changed
      // This helps us prevent sending duplicated events
      if (modalVisible === prevModalVisible) {
        return;
      }

      if (modalVisible) {
        try {
          postMessageCallback({
            type: 'sheetmodalopened',
          });
          dispatchEvent('modalopened');
        } catch (error) {
          // Create an easily identifiable error message to help
          // debug issues with the CheckoutModal conversion drop
          notify(
            new Error(
              `Error before calling onModalVisibleChange(true): ${error}`,
            ),
          );
        }

        onModalVisibleChange?.(true);
        return;
      }

      postMessageCallback({
        type: 'sheetmodalclosed',
      });
      dispatchEvent('modalclosed');
      onModalVisibleChange?.(false);

      // Remove the 1password custom element from the DOM after the sheet modal is closed.
      isoDocument.querySelector('com-1password-notification')?.remove();
    }, [
      dispatchEvent,
      modalVisible,
      onModalVisibleChange,
      postMessageCallback,
      prevModalVisible,
      notify,
    ]);

    useImperativeHandle(ref, () => {
      return {
        close: handleDismissModal,
        iframeRef,
        loaded,
        open: handleShowModal,
        postMessage: postMessageCallback,
        sessionDetected,
        source: iframeRef.current?.contentWindow || null,
        visible: modalVisible,
        waitForMessage,
      };
    }, [
      handleDismissModal,
      handleShowModal,
      loaded,
      modalVisible,
      postMessageCallback,
      sessionDetected,
      waitForMessage,
    ]);

    useEffect(() => {
      initLoadTimeout();
      leaveBreadcrumb('Iframe url updated', {src}, 'state');
    }, [initLoadTimeout, leaveBreadcrumb, src]);

    useEffect(() => {
      if (modalVisible) {
        trackPageImpression({page: 'AUTHORIZE_MODAL'});
      }
    }, [modalVisible, trackPageImpression]);

    useEffect(() => {
      const parent = iframeRef.current?.parentNode;
      if (!parent || !iframeRef.current) {
        return;
      }
      parent.removeChild(iframeRef.current);
      iframeRef.current.setAttribute('src', src);
      parent.appendChild(iframeRef.current);
    }, [src]);

    const handleModalInViewport = () => {
      trackPageImpression({
        page: 'AUTHORIZE_MODAL_IN_VIEWPORT',
        allowDuplicates: true,
      });
      leaveBreadcrumb('modal in viewport', {}, 'state');
    };

    return (
      <Modal
        anchorTo={anchorTo}
        hideHeader={!modalHeaderVisible}
        onDismiss={handleDismissModal}
        onModalInViewport={handleModalInViewport}
        variant={variant}
        visible={modalVisible}
      >
        <iframe
          allow={allowAttribute || 'publickey-credentials-get *'}
          className="relative z-40 m-auto w-full border-none"
          ref={callbackRef}
          tabIndex={0}
          data-testid="authorize-iframe"
        />
      </Modal>
    );
  },
);

AuthorizeIframe.displayName = 'AuthorizeIframe';

function isRef(ref: RefObject<HTMLElement>): ref is RefObject<HTMLElement> {
  return Object.prototype.hasOwnProperty.call(ref, 'current');
}
