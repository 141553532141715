import 'construct-style-sheets-polyfill';
import type {ComponentChildren} from 'preact';
import {createPortal} from 'preact/compat';
import {useLayoutEffect, useRef, useState} from 'preact/hooks';

import {RootProvider} from '~/foundation/RootProvider/RootProvider';
import type {PortalProviderVariant} from '~/types/portalProvider';

import styles from '../../styles.css';

interface PortalProviderProps {
  children?: ComponentChildren;
  instanceId: string;
  type: 'modal' | 'toast';
  variant: PortalProviderVariant;
}

export function PortalProvider({
  children,
  instanceId,
  type,
  variant,
}: PortalProviderProps) {
  const root = useRef<HTMLDivElement | null>(null);
  const [shadowRoot, setShadowRoot] = useState<ShadowRoot | null>(null);

  useLayoutEffect(() => {
    setShadowRoot(root.current!.attachShadow({mode: 'open'}));
  }, []);

  useLayoutEffect(() => {
    if (shadowRoot) {
      const stylesheet = new CSSStyleSheet();
      stylesheet.replace(styles);

      shadowRoot.adoptedStyleSheets = [stylesheet];
    }
  }, [shadowRoot]);

  return (
    <div
      data-nametag="shop-portal-provider"
      data-portal-instance-id={instanceId}
      data-type={type}
      data-variant={variant}
      ref={root}
      style={{all: 'initial'}}
    >
      {shadowRoot &&
        createPortal(
          <RootProvider monorailEnabled={false}>{children}</RootProvider>,
          shadowRoot,
        )}
    </div>
  );
}
