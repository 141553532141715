import {BugsnagLight, parseErrorStack} from '@shopify/bugsnag-light-core';
import type {
  BreadcrumbType,
  BugsnagClient,
  BugsnagEvent,
  Metadata,
  NotifyOptions,
  ReleaseStage,
} from '@shopify/bugsnag-light-core';

import {isoDocument} from '~/utils/document';
import {isoNavigator} from '~/utils/navigator';
import {isoWindow} from '~/utils/window';

import config from '../../config';

function addGenericBugsnagOnError(bugsnagClient: BugsnagClient) {
  function isShopJsError(error: Error): boolean {
    const stack = parseErrorStack(error);
    if (stack.length === 0) {
      return false;
    }

    return stack.some((stackTrace) =>
      stackTrace.file?.includes('shopifycloud/shop-js'),
    );
  }

  isoWindow.addEventListener('error', (event) => {
    const {error} = event;
    if (error && isShopJsError(error)) {
      bugsnagClient.notify(error);
    }
  });
}

function createBugsnagParams(metadata: object) {
  return {
    apiKey: config.bugsnagApiKey,
    appId: 'shop-js',
    appVersion: '__buildVersionBeta',
    metadata: {
      // eslint-disable-next-line no-process-env
      bundleLocale: process.env.BUILD_LOCALE,
      ...metadata,
    } as any,
    onError: (event: BugsnagEvent) => {
      const groupingHash = `${event.exceptions[0].errorClass}:${event.exceptions[0].message}`;

      const featureAssets: Record<string, any[]> | undefined =
        isoWindow.Shopify?.featureAssets?.['shop-js'];

      const featureAssetsNonEmpty = Boolean(
        featureAssets && Object.keys(featureAssets).length > 0,
      );

      const shopJsUrls = (
        Array.from(
          isoDocument.querySelectorAll('script[src*="/shop-js/"]'),
        ) as HTMLScriptElement[]
      ).map((scriptTag) => scriptTag.src);

      event.device = {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        locale: isoNavigator.userLanguage || isoNavigator.language,
        userAgent: isoNavigator.userAgent,
        orientation: isoWindow.screen?.orientation?.type,
        time: new Date().toISOString(),
      };

      event.groupingHash = groupingHash;

      event.metaData = {
        ...event.metaData,
        ...{
          compactUX: true,
          domain: isoWindow?.location?.hostname,
          shopJsUrls,
          shopJsFeatureAssetsExist: featureAssetsNonEmpty,
        },
        // Gross, just casting this temporarily.
      } as unknown as Metadata;

      event.request = {
        url: isoWindow.location.href,
      };
    },
    // eslint-disable-next-line no-process-env
    releaseStage: (process.env.NODE_ENV || 'production') as ReleaseStage,
  };
}

export class Bugsnag {
  readonly client: BugsnagLight;

  constructor(feature?: string) {
    const params = createBugsnagParams({
      beta: true,
      feature,
    });

    this.client = new BugsnagLight(params);
    this.leaveBreadcrumb = this.leaveBreadcrumb.bind(this);
    this.notify = this.notify.bind(this);

    addGenericBugsnagOnError(this.client);
  }

  leaveBreadcrumb(name: string, metaData: any, type: BreadcrumbType) {
    if (!this.client) {
      // eslint-disable-next-line no-console
      console.log('Bugsnag.leaveBreadcrumb() called before client creation.');
      return;
    }

    // eslint-disable-next-line no-process-env
    if (process.env.NODE_ENV === 'spin') {
      // eslint-disable-next-line no-console
      console.log('[Bugsnag leaveBreadcrumb called]', name, metaData, type);
      return;
    }

    this.client.leaveBreadcrumb(name, metaData, type);
  }

  notify(error: Error, options?: NotifyOptions) {
    if (!this.client) {
      // eslint-disable-next-line no-console
      console.warn('Bugsnag.notify() called before client creation.');
      return;
    }

    // eslint-disable-next-line no-process-env
    if (process.env.NODE_ENV === 'spin') {
      // eslint-disable-next-line no-console
      console.log('[Bugsnag notify called]', error);
      return;
    }

    this.client.notify(error, options);
  }
}
